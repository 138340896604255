import cx from 'classnames';
import IconInfo from 'images/Icon-Info.svg';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { P } from 'app/components/common/Typography';
import protocolNames from 'app/utils/protocolNames';
import React, { useState } from 'react';
import Checkbox from 'app/components/common/Checkbox';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySupplementInfo from 'app/components/customer/Checkout/ModalBodySupplementInfo';
import { useFormikContext } from 'formik';
import { MultiMonthPlanMapping } from 'app/constants/Products';
import PriceCalculator from 'app/helpers/priceCalculator';

type Props = {
  currentProductName: string;
  activeProductWithSupplementName: string | undefined;
  multimonthPlan: number;
  supplementsOnAnotherIntake: boolean;
  bbOutOfStock: boolean;
  multimonthEnabled: boolean;
  priceCalculator: PriceCalculator;
  encloPregNewPricing: boolean;
  isOnboarding: boolean;
};

const GenericBuildingBlocks = ({
  activeProductWithSupplementName,
  bbOutOfStock,
  multimonthEnabled,
  multimonthPlan,
  priceCalculator,
  supplementsOnAnotherIntake = false,
  encloPregNewPricing = false,
  isOnboarding,
}: Props) => {
  const [openModalSupplementInfo, setOpenModalSupplementInfo] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    include_supplement: boolean;
  }>();
  const { include_supplement } = values;

  if (values.include_supplement === null) {
    // Default to checked state if onboarding, unless bbOutOfStock is true or supplementsOnAnotherIntake is true
    values.include_supplement = isOnboarding;
  }

  const bbOutOfStockWidget = () =>
    bbOutOfStock && (
      <div className="generic_payment_page__plan_summary plan_summary">
        <p className="bb_preorder__title">
          Due to high demand, the Building Blocks supplement is currently out of stock, but it will be restocked soon.
        </p>
        <p>Pre-order Building Blocks by checking the box above.</p>
        <p>
          You won't be charged until it's restocked, and then $49.99 will be added to your subscription for future
          shipments
        </p>
        <p className="mt12">
          Note: The total monthly subscription reflects the Building Blocks pre-order, but you'll only be charged the
          full amount once it's back in stock.
        </p>
      </div>
    );

  const renderBBDiscount = () => {
    if (supplementsOnAnotherIntake) return null;

    if (priceCalculator.bbPotentialDiscount() > 0) {
      return (
        <div className={cx('price_row')}>
          <div></div>
          <PriceFormatter price={priceCalculator.REGULAR_BB_PRICE} className="payment_method__old_price" />
        </div>
      );
    }
  };

  // TODO: Refactor this, move to checkoutHelper
  const renderDescription = () => (
    <P className={cx('mb12 mt12')}>
      {encloPregNewPricing ? (
        <ul style={{ listStyleType: 'disc', paddingLeft: '15px' }}>
          <li>Pharmaceutical grade made in USA</li>
          <li>Increase Vitamin D, proven by blood tests</li>
          <li>Supports hormone optimization and energy</li>
        </ul>
      ) : (
        <>
          Prescription-strength multivitamin that supports hormone optimization & energy production.{' '}
          <img className="info-icon clickable" onClick={() => setOpenModalSupplementInfo(true)} src={IconInfo} alt="" />
        </>
      )}
    </P>
  );

  return (
    <>
      {encloPregNewPricing && renderBBDiscount()}
      <div className={cx('price_row')}>
        <div>
          <Checkbox
            className="include-supplements-label"
            label={
              <div className={cx('price_row', { opacity: !include_supplement })}>
                <div>
                  <h4 className="generic_payment_page__bb_label">Add Building Blocks:</h4>
                </div>
              </div>
            }
            testId="include-supplement"
            name="include_supplement"
            checked={!supplementsOnAnotherIntake && !bbOutOfStock && values.include_supplement}
            disabled={supplementsOnAnotherIntake || bbOutOfStock}
            onChange={(evt) => setFieldValue('include_supplement', evt.target.checked)}
          />
        </div>
        <PriceFormatter
          className={cx({ opacity: !include_supplement })}
          price={priceCalculator.bbPrice()}
          period="mo"
        />
      </div>
      <div className="generic_payment_page__bb_info">
        {multimonthEnabled && multimonthPlan !== 1 && priceCalculator.bbPotentialDiscount() > 0 && (
          <P
            className={cx('mt12 generic_payment_page__bb_info__enjoy', {
              selected: include_supplement,
            })}
          >
            ${priceCalculator.bbPotentialDiscount() / 100}
            <span className="generic_payment_page__per_month_label">/mo</span> Off{' '}
            {MultiMonthPlanMapping[multimonthPlan]} Plan
          </P>
        )}
        {renderDescription()}
      </div>
      {supplementsOnAnotherIntake && activeProductWithSupplementName && (
        <P className="payment_method__disabled_alert">
          You are already subscribed to Building Blocks as part of your {protocolNames[activeProductWithSupplementName]}{' '}
          Protocol subscription.
        </P>
      )}
      {!supplementsOnAnotherIntake && bbOutOfStockWidget()}
      <SetProductDialog
        isOpen={openModalSupplementInfo}
        text={<ModalBodySupplementInfo />}
        onClose={() => setOpenModalSupplementInfo(false)}
        className="manage_subscription__cancel_modal supplement_info align-left maximized"
      />
    </>
  );
};

export default GenericBuildingBlocks;
