import React from 'react';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { AvailableProducts } from 'app/constants/Products';

type Props = {
  productName: AvailableProducts;
  subProductName: string;
  handleChangeProduct?: (e: any) => void;
  handleChangeLab?: (e: any) => void;
};

const MultimonthPaymentHeader = ({ productName, subProductName, handleChangeProduct, handleChangeLab }: Props) => {
  const breadcrumbs = (
    <>
      {handleChangeProduct && (
        <span data-testid="change-product" onClick={handleChangeProduct} className="generic_payment_page__change_subs">
          Change Product
        </span>
      )}
      {handleChangeLab && handleChangeProduct && ' | '}
      {handleChangeLab && (
        <span data-testid="change-lab" onClick={handleChangeLab} className="generic_payment_page__change_subs">
          Change Lab Selection
        </span>
      )}
    </>
  );

  return (
    <div className="payment_card pb4">
      <ProductHeader
        productName={productName}
        subProductName={subProductName}
        breadcrumbs={breadcrumbs}
        showTitle={true}
      />
    </div>
  );
};

export default MultimonthPaymentHeader;
