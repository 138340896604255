import React from 'react';
import { AvailableProducts } from 'app/constants/Products';
import MultimonthPaymentHeader from 'app/components/customer/steps/Payment/Generic/MultimonthPaymentHeader';
import WarriorPaymentHeader from 'app/components/customer/steps/Payment/warrior/PaymentHeader';
import LoverTreatmentPlan from 'app/components/customer/steps/Payment/lover/LoverTreatmentPlan';
import PriceCalculator from 'app/helpers/priceCalculator';
import { Product, CheckoutHelper } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import MonthlyTreatmentPlanProducts from 'app/components/customer/steps/Payment/magician/MonthlyTreatmentPlanProducts';

type Props = {
  currentProductName: AvailableProducts;
  multimonthEnabled: boolean;
  multimonthPeriod: string;
  subProductName: string;
  checkoutHelper: CheckoutHelper;
  product?: ImmutableMap<Product>;
  priceCalculator: PriceCalculator;
  handleChangeProduct?: (e: any) => void;
  handleChangeLab?: (e: any) => void;
};

// TODO: refactor this file using checkoutHelper, get rid of products dependencies
const GenericSubscriptionDetails = ({
  currentProductName,
  product,
  priceCalculator,
  multimonthEnabled,
  multimonthPeriod,
  subProductName,
  handleChangeProduct,
  handleChangeLab,
  checkoutHelper,
}: Props) => {
  const renderMultimonthHeader = () => (
    <MultimonthPaymentHeader
      productName={currentProductName}
      priceCalculator={priceCalculator}
      multimonthPeriod={multimonthPeriod}
      handleChangeProduct={checkoutHelper.showChangeProductButton() ? handleChangeProduct : undefined}
      handleChangeLab={handleChangeLab}
      subProductName={subProductName}
    />
  );
  const renderDetails = () => {
    if (multimonthEnabled) {
      return renderMultimonthHeader();
    }

    switch (currentProductName) {
      case AvailableProducts.Lover:
        return <LoverTreatmentPlan product={product} />;
      case AvailableProducts.Magician:
        return <MonthlyTreatmentPlanProducts />;
      case AvailableProducts.Warrior:
        return <WarriorPaymentHeader priceCalculator={priceCalculator} />;
    }

    return null;
  };

  return renderDetails();
};

export default GenericSubscriptionDetails;
