/* eslint-disable jsx-a11y/label-has-for */
import React, { ComponentProps, FC, ReactNode } from 'react';
import { Field, useField } from 'formik';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';

const CustomInputRow: FC<{
  className?: string;
  label?: ReactNode;
  name: string;
  type?: ComponentProps<typeof Field>['type'];
  testId?: string;
  rightElement?: ReactNode;
}> = ({ className, label, name, type = 'number', testId, rightElement }) => {
  const [, meta] = useField(name);
  return (
    <div className={cx('custom_modal_input_row', className)}>
      <label>{label}</label>
      <div>
        <Field
          className={cx({ initial: !meta.touched, valid: meta.touched && !meta.error, error: meta.error })}
          name={name}
          type={type}
          step="any"
          data-testid={testId}
        />
        {meta.error && <span className="error_message">{meta.error}</span>}
      </div>
      <div>
        {rightElement}
        {meta.error && <Icon className="warning" size={20} icon="warning-sign" />}
        {!meta.error && meta.touched && <Icon className="valid" size={20} icon="tick" />}
      </div>
    </div>
  );
};

export default CustomInputRow;
