import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import PriceCalculator from 'app/helpers/priceCalculator';
import CouponCodeInput from '../CouponCodeInput';
import DiscountCouponsList from '../DiscountCouponsList';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { P } from 'app/components/common/Typography';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntakeProduct } from 'app/selectors/customer';
import * as selectors from 'app/selectors/customer';
import { ProductContext } from 'app/components/customer/Product';

type Props = {
  title?: string;
  multimonthPeriod: string;
  isOnboarding: boolean;
  showLabKit: boolean;
  priceCalculator: PriceCalculator;
  intake: any;
};

const GenericDueToday = ({ title, isOnboarding, priceCalculator, multimonthPeriod, showLabKit, intake }: Props) => {
  const { values } = useFormikContext<{
    use_own_lab: boolean;
    lab_kit_type: string;
  }>();
  const { use_own_lab, lab_kit_type } = values;

  const productCtx = useContext(ProductContext);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  return (
    <>
      <TitleWithLine className="mt52">{title ? title : 'Due Today'}</TitleWithLine>
      <PaymentCard>
        {isOnboarding && (
          <div className="price_row mb24">
            <div>
              Subscription{' '}
              <span className="generic_payment_page__multi_month_variant">({multimonthPeriod.toLowerCase()})</span>
            </div>
            <div>
              <PriceFormatter price={priceCalculator.subscriptionCost()} />
            </div>
          </div>
        )}
        {!priceCalculator.freeShipping() && (
          <div className="price_row mt24">
            <div>Priority 2 day shipping</div>
            <div>
              <PriceFormatter price={priceCalculator.shippingPrice()} />
            </div>
          </div>
        )}
        {!use_own_lab && showLabKit && (
          <>
            <div className="price_row mt24">
              <div>{lab_kit_type === LabKitTypes.WalkIn ? 'Walk-in Lab' : 'At-Home Lab Kit:'}</div>
              <PriceFormatter price={priceCalculator.labCost()} />
            </div>
            {currentProductName === AvailableProducts.King && (
              <P className="lab_step_paragraph_format">
                {lab_kit_type === LabKitTypes.WalkIn
                  ? 'Lab Kit charges apply to this month and the next 2 months only'
                  : 'Lab Kit charges apply to this month and next month only'}
              </P>
            )}
          </>
        )}
        {priceCalculator.appointmentCost() > 0 && (
          <>
            <div className="price_row mt24">
              <div>Video Consultation</div>
              <div>
                <PriceFormatter price={priceCalculator.appointmentCost()} showCents={true} />
              </div>
            </div>
            <div className="due_today_description">
              A video consult with your Maximus doctor is required before beginning treatment. Instructions for
              scheduling to follow.
            </div>
          </>
        )}

        <div className="discount-coupons-container">
          <h4 className="subtitle">Coupons</h4>
          <DiscountCouponsList priceCalculator={priceCalculator} />
          <div className="price_row">
            <CouponCodeInput
              productName={intake?.get('product_name')}
              appliedDiscounts={product?.get('discounts')?.toArray()?.length}
            />
          </div>
        </div>

        <Divider className="mt32 mb32" />
        <div className="price_row">
          <div>TOTAL:</div>
          <PriceFormatter price={priceCalculator.totalDueToday()} showCents={true} />
        </div>
      </PaymentCard>
    </>
  );
};

export default GenericDueToday;
