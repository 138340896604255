import React, { useContext } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectManagedProduct } from 'app/selectors/customer';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import './css/DiscountCouponsList.scss';
import { ProductContext } from 'app/components/customer/Product';
import * as selectors from 'app/selectors/customer';

const CouponItem = ({ key, discount, priceCalculator }) => {
  const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';
  const price = priceCalculator.discountPrice(discount);

  return price > 0 ? (
    <div key={key} className="price_row mt24">
      <div>
        {`“${discount.get('coupon').get('code')}” applied for ${appliedFor}.`}
        <br />
        {discount.get('coupon').get('name')}
      </div>
      <PriceFormatter price={price} showCents={true} negative={true} />
    </div>
  ) : null;
};

const DiscountCouponsList = ({ priceCalculator }) => {
  const productCtx = useContext(ProductContext);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  let discounts = product.get('discounts');

  if (discounts == null) {
    const currentProduct = useAppSelector(selectManagedProduct);

    discounts = currentProduct.get('intakes').last().get('discounts');
  }

  return (
    <div className="discount-coupons-list">
      {discounts?.map((discount) => (
        <CouponItem key={discount.get('id')} discount={discount} priceCalculator={priceCalculator} />
      ))}
    </div>
  );
};

export default DiscountCouponsList;
