import React from 'react';
import DrugsBottle from 'images/sub-products/king/king-drugs.png';
import { useFormikContext } from 'formik';
import { KING_MONTHLY_PRICE_BB_INCLUDED } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';

export const MultimonthConfig = [
  {
    value: 12,
    specialLabel: 'Best Value',
    savings: '$1200 yearly savings',
    subheader: 'Bills every 12 months',
    title: 'Yearly Plan',
  },
  {
    value: 3,
    specialLabel: 'Most Popular',
    savings: '$600 yearly savings',
    subheader: 'Bills every 3 months',
    title: 'Quarterly Plan',
  },
  {
    value: 1,
    subheader: 'Bills every month',
    title: 'Monthly Plan',
  },
];

const KingMultimonthPlanSelectionForm = ({ handleChangeProduct, intake }) => {
  const kingV2Offered = intake.get('king_v2_offered');
  const encloPregNewPricing = intake.get('enclo_preg_new_pricing');

  const { values } = useFormikContext<{
    multimonth_plan: number;
    selected_king_v2_product: string;
  }>();
  const selectedSubProduct = (kingV2Offered && values.selected_king_v2_product) || KingV2SubProducts.EncloPregnolone;

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.King}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={MultimonthConfig}
      handleChangeProduct={handleChangeProduct}
      showBreadcrumbs={kingV2Offered}
      drugsImage={DrugsBottle}
      priceConfig={KING_MONTHLY_PRICE_BB_INCLUDED}
      encloPregNewPricing={encloPregNewPricing}
    />
  );
};

export default KingMultimonthPlanSelectionForm;
