import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { P } from 'app/components/common/Typography';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscription from 'app/components/customer/Checkout/ModalBodySubscription';
import { AvailableProducts } from 'app/constants/Products';
import PriceCalculator from 'app/helpers/priceCalculator';
import PlanServices from 'app/components/customer/steps/Payment/GenericExp/PlanServices';

const BILLED_EVERY_INFO = {
  1: 'month',
  3: '3 months',
  12: '12 months',
};

const MODAL_DAYS_MAPPING = {
  magician: {
    1: 28,
  },
  default: {
    1: 30,
    3: 90,
    12: 360,
  },
};

type Props = {
  multimonthPeriod: string;
  multimonthPlan: number;
  nextBillingCycle: string;
  currentProductName: string;
  isOnboarding: boolean;
  priceCalculator: PriceCalculator;
};

const GenericSubscriptionSummary = ({
  isOnboarding,
  nextBillingCycle,
  multimonthPeriod,
  multimonthPlan,
  priceCalculator,
  currentProductName,
}: Props) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);

  const subscriptionPeriodDays = () => {
    if (MODAL_DAYS_MAPPING[currentProductName] && MODAL_DAYS_MAPPING[currentProductName][multimonthPlan]) {
      return MODAL_DAYS_MAPPING[currentProductName][multimonthPlan];
    }

    return MODAL_DAYS_MAPPING['default'][multimonthPlan];
  };

  const billingPeriod = () => {
    switch (currentProductName) {
      case AvailableProducts.Magician:
        return '28 days';
      case AvailableProducts.King:
        return BILLED_EVERY_INFO[multimonthPlan];
      default:
        return '30 days';
    }
  };

  return (
    <>
      <div className="price_row mt24">
        <div>
          <h2>
            Subscription Cost
            <span className="generic_payment_page__multi_month_variant"> ({multimonthPeriod.toLowerCase()})</span>
          </h2>
        </div>
        <PriceFormatter price={priceCalculator.subscriptionCost()} />
      </div>
      <PlanServices />
      <div className="generic_payment_page__plan_summary plan_summary">
        {isOnboarding && (
          <P>
            Bills every {billingPeriod()}. Subscription auto-renews unless a cancelation request is placed before the
            end of the billing cycle.
            <br />
            <a href="#" onClick={() => setOpenModalSubscription(true)}>
              Full details
            </a>
            .
          </P>
        )}
        {!isOnboarding && (
          <>
            <P>
              Your new subscription cost will start on your{' '}
              <span className="bold">next billing cycle, {nextBillingCycle}</span>, and will auto-renew every{' '}
              {BILLED_EVERY_INFO[multimonthPlan]}
            </P>
            <P className="mt24">
              Any changes to what is included in your monthly shipments will begin after this billing date.{' '}
              <a href="#" onClick={() => setOpenModalSubscription(true)}>
                Full details
              </a>
              .
            </P>
          </>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays()}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummary;
