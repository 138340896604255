import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import PlanServices from 'app/components/customer/steps/Payment/GenericExp/PlanServices';
import { P } from 'app/components/common/Typography';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscription from 'app/components/customer/Checkout/ModalBodySubscription';
import PriceCalculator from 'app/helpers/priceCalculator';

const BILLED_EVERY_INFO = {
  1: 'month',
  3: '3 months',
  12: '12 months',
};

const BILLED_EVERY_INFO_SHORT = {
  1: 'mo',
  3: '3 mos',
  12: 'year',
};

const MODAL_DAYS_MAPPING = {
  1: 30,
  3: 90,
  12: 360,
};

type Props = {
  multimonthPlan: number;
  nextBillingCycle: string;
  isOnboarding: boolean;
  productChanged: boolean;
  priceCalculator: PriceCalculator;
};

const GenericSubscriptionSummaryKingV2 = ({
  isOnboarding,
  nextBillingCycle,
  multimonthPlan,
  priceCalculator,
  productChanged,
}: Props) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const subscriptionPeriodDays = MODAL_DAYS_MAPPING[multimonthPlan];

  return (
    <>
      <div className="price_row mt24">
        <div>
          <P>Total Monthly Cost</P>
        </div>
        <PriceFormatter price={priceCalculator.fullMonthlyCost()} />
      </div>
      <PlanServices />
      <div className="generic_payment_page__plan_summary plan_summary">
        <div className="price_row mb12">
          <div>
            <h2>Subscription Cost</h2>
          </div>
          <PriceFormatter price={priceCalculator.subscriptionCost()} period={BILLED_EVERY_INFO_SHORT[multimonthPlan]} />
        </div>
        {isOnboarding && (
          <P>
            Your subscription bills upfront and auto-renews every {BILLED_EVERY_INFO[multimonthPlan]} (
            {subscriptionPeriodDays} days)* unless a cancelation request is placed before the end of the billing cycle.
            Medication ships monthly to allow for treatment customization.
            <br />
            {' *'}
            <a href="#" onClick={() => setOpenModalSubscription(true)}>
              Full details
            </a>
            .
          </P>
        )}
        {!isOnboarding && (
          <>
            <P className="mb12">
              Your new subscription cost will start on your{' '}
              <span className="bold">next billing cycle, {nextBillingCycle}</span>, and will auto-renew every{' '}
              {BILLED_EVERY_INFO[multimonthPlan]} ({subscriptionPeriodDays} days).
              <br />
              <a href="#" onClick={() => setOpenModalSubscription(true)}>
                Full subscription details
              </a>
              .
            </P>
            {productChanged && (
              <div>
                <P>
                  <span className="bold">When will I start receiving my new product choice?</span>
                </P>
                <P className="mb12">
                  You will start receiving your new product choice after your next billing cycle once your subscription
                  is updated.
                </P>
                <P>
                  If you’d like to start receiving your <span className="bold">new product choice</span> sooner, you
                  must contact the Maximus concierge team to have your current subscription updated prior to your next
                  billing cycle.
                </P>
              </div>
            )}
          </>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummaryKingV2;
