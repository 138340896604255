import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { CheckoutHelper } from 'app/types/admin/customerUser';
import PriceCalculator from 'app/helpers/priceCalculator';

type Props = {
  onMultimonthPlanChange?: (e: any) => void;
  subProductName: string;
  checkoutHelper: CheckoutHelper;
  priceCalculator: PriceCalculator;
};

const PlanWidget = ({ onMultimonthPlanChange, subProductName, checkoutHelper, priceCalculator }: Props) => {
  const { setFieldValue } = useFormikContext<{
    multimonth_plan: number;
  }>();

  const [planModalOpen, setPlanModalOpen] = useState(false);

  const onMultimonthUpdate = (value) => {
    setFieldValue('multimonth_plan', value);
    if (onMultimonthPlanChange) {
      onMultimonthPlanChange(value);
    }
    setPlanModalOpen(false);
  };

  return (
    <div className="flex mt16">
      <span className="generic_payment_page__change_subs" onClick={() => setPlanModalOpen(true)}>
        Change Plan
      </span>

      <SetProductDialog
        isOpen={planModalOpen}
        title={<h2 className="title_without_line xl">Select a Plan</h2>}
        text={checkoutHelper.renderPlanSelection(onMultimonthUpdate, subProductName, priceCalculator)}
        onClose={() => setPlanModalOpen(false)}
        className="manage_subscription__cancel_modal generic_payment_page__modal align-left pb32 plan_modal"
      />
    </div>
  );
};

export default PlanWidget;
