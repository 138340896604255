import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import { useFormikContext } from 'formik';
import axios from 'axios';
import { getCSRF } from 'app/api';
import ModalBodyOwnLab from 'app/components/customer/Checkout/ModalBodyOwnLab';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import ModalBodyAtHomeLab from 'app/components/customer/Checkout/ModalBodyAtHomeLab';
import LabWidget from 'app/components/customer/steps/Payment/commonBYOL/LabWidget';
import { LabKitTypes } from 'app/constants/Products';
import PriceCalculator from 'app/helpers/priceCalculator';

const modalTitles = {
  king: 'Using your own lab test. Read carefully.',
  magician: 'Using your own lab test.',
};

type Props = {
  intake: any;
  priceCalculator: PriceCalculator;
  kingV2OralTrt?: boolean;
  byolFormSubmitted?: boolean;
  setByolFormSubmitted?: any;
  setByolParams?: any;
  byolParams?: any;
  isLabSelectionStep?: boolean;
};
const GenericBYOL = ({
  intake,
  priceCalculator,
  kingV2OralTrt = false,
  byolFormSubmitted = false,
  setByolFormSubmitted = null,
  setByolParams = null,
  byolParams = null,
  isLabSelectionStep = false,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    use_own_lab?: boolean;
    lab_kit_type?: string;
    own_lab_file_uploaded?: boolean;
    own_lab_file_name?: string;
  }>();

  const customer = useAppSelector(selectCustomer);

  const [openModalAtHomeLab, setOpenModalAtHomeLab] = useState<null | string | boolean>(null);
  const [ownLabFile, setOwnLabFile] = useState<File | null>(null);
  const [openModalOwnLab, setOpenModalOwnLab] = useState(false);
  const [ownLabFileIsDeleting, setOwnLabFileIsDeleting] = useState(false);
  const [ownLabFileIsLoading, setOwnLabFileIsLoading] = useState(false);
  const [uploadNotification, setUploadNotification] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileError, setFileError] = useState('');

  const inputFile = useRef<HTMLInputElement>(null);
  const byolForm = useRef<HTMLFormElement>(null);
  const productName = intake.get('product_name');
  const labPrefix = productName === 'king' ? '' : 'magician_';
  const {
    use_own_lab = customer.get(`${labPrefix}use_own_lab`),
    lab_kit_type = customer.get('king_lab_kit_type'),
    own_lab_file_uploaded = customer.get(`${labPrefix}own_lab_file_uploaded`),
    own_lab_file_name = customer.get(`${labPrefix}own_lab_file_name`),
  } = values;
  const ownLabFileName = ownLabFile?.name || own_lab_file_name;
  const photoPurpose = `${productName}_onboarding_lab`;
  const intake_name = intake.get('name');

  useEffect(() => {
    if (ownLabFile) submitOwnLabFile();
  }, [ownLabFile]);

  const removeOwnLabfile = async () => {
    setOwnLabFileIsDeleting(true);

    await axios
      .post(
        '/api/commands',
        {
          user_id: customer.get('id'),
          intake_name,
          photo_purpose: photoPurpose,
          cmdType: 'delete_own_lab',
          type: 'delete_own_lab',
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      )
      .then(() => {
        setFieldValue('own_lab_file_uploaded', false);
        setFieldValue('own_lab_file', null);
        setFieldValue('own_lab_file_name', null);
        setOwnLabFile(null);
        setOwnLabFileIsDeleting(false);
        setUploadNotification('Your lab was deleted successfully.');
        setFileError('');
      })
      .catch(() => setOwnLabFileIsDeleting(false));
  };
  const handlePhotoUpload = () => inputFile.current?.click();

  const submitOwnLabFile = async () => {
    const raw_params = {
      file: ownLabFile,
      user_id: customer.get('id'),
      intake_name,
      photo_purpose: photoPurpose,
      cmdType: 'upload_own_lab',
      type: 'upload_own_lab',
    };

    const data = new FormData();

    for (const key in raw_params) {
      data.append(key, raw_params[key]);
    }

    setOwnLabFileIsLoading(true);
    setUploadNotification('');
    setFileError('');

    const onUploadProgress = ({ loaded, total }: { loaded: number; total?: number }) => {
      if (!total) return;
      setUploadProgress(loaded / total);
    };

    await axios
      .post('/api/commands', data, { headers: { 'X-CSRF-Token': getCSRF() }, onUploadProgress })
      .then(() => {
        setOwnLabFileIsLoading(false);
        setFieldValue('own_lab_file_uploaded', true);
        setUploadNotification('Your lab was uploaded successfully.');
      })
      .catch(({ response }) => {
        setFileError(response?.data?.errors?.file);
        setOwnLabFileIsLoading(false);
      });
  };

  const uploadLabResults = async (params) => {
    await axios
      .post(
        '/api/commands',
        {
          ...params,
          user_id: customer.get('id'),
          intake_name,
          photo_purpose: photoPurpose,
          cmdType: 'upload_own_lab_results',
          type: 'upload_own_lab_results',
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      )
      .then(() => setOpenModalOwnLab(false))
      .catch(({ response }) => {
        setFileError(response?.data?.errors?.file);
      });
  };

  const labModalInfoTitle = () => {
    switch (openModalAtHomeLab) {
      case LabKitTypes.AtHomeLabKit:
        return 'At-Home Lab Testing';
      case LabKitTypes.WalkIn:
        return 'Walk-In Lab';
      case LabKitTypes.UseYourOwnLab:
        return 'Use Your Own Lab Tests';
      default:
        return '';
    }
  };

  return (
    <>
      <LabWidget
        setOpenModalAtHomeLab={setOpenModalAtHomeLab}
        use_own_lab={use_own_lab}
        lab_kit_type={lab_kit_type}
        setFieldValue={setFieldValue}
        own_lab_file_uploaded={own_lab_file_uploaded}
        inputFile={inputFile}
        setOwnLabFile={setOwnLabFile}
        setOpenModalOwnLab={setOpenModalOwnLab}
        intake={intake}
        byolFormSubmitted={byolFormSubmitted}
        priceCalculator={priceCalculator}
        kingV2OralTrt={kingV2OralTrt}
        labSelectionStep={isLabSelectionStep}
      />
      <SetProductDialog
        isOpen={!!openModalAtHomeLab}
        title={labModalInfoTitle()}
        text={
          <ModalBodyAtHomeLab
            setOpenModalAtHomeLab={setOpenModalAtHomeLab}
            openModalAtHomeLab={openModalAtHomeLab}
            productName={productName}
            kingV2OralTrt={kingV2OralTrt}
          />
        }
        onClose={() => setOpenModalAtHomeLab(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalOwnLab}
        title={modalTitles[intake.get('product_name')]}
        notification={uploadNotification}
        text={
          <ModalBodyOwnLab
            removeOwnLabfile={removeOwnLabfile}
            setOpenModalOwnLab={setOpenModalOwnLab}
            handlePhotoUpload={handlePhotoUpload}
            product={intake.get('product_name')}
            isOralTrt={kingV2OralTrt}
            ownLabFileName={ownLabFileName}
            isLoading={ownLabFileIsLoading}
            isDeleting={ownLabFileIsDeleting}
            uploadProgress={uploadProgress}
            ownLabFileUploaded={own_lab_file_uploaded}
            uploadLabResults={uploadLabResults}
            fileError={fileError}
            setByolFormSubmitted={setByolFormSubmitted}
            setByolParams={setByolParams}
            byolParams={byolParams}
            byolForm={byolForm}
          />
        }
        onClose={() => setOpenModalOwnLab(false)}
        className="manage_subscription__cancel_modal align-left maximized own_lab_dialog"
      />
    </>
  );
};

export default GenericBYOL;
