import { P } from 'app/components/common/Typography';
import WhiteButton from 'app/components/common/WhiteButton';
import React from 'react';
import ExplainSemaglutideChart from 'images/explain_semaglutide_chart.svg';
import ExplainTirzepatideChart from 'images/explain_tirzepatide_chart.svg';

interface Props {
  setModalTreatmentPlanInfo: (value: boolean) => void;
}

const ModalBodyTreatmentInformation = ({ setModalTreatmentPlanInfo }: Props) => (
  <>
    <h1>Treatment Information</h1>

    <h2>What is best for me?</h2>

    <P className="mb12">
      Both semaglutide (brand name Wegovy®) and tirzepatide (brand name Mounjaro™) have been shown to be extremely
      effective in helping patients lose weight. Semaglutide has been shown to help patients lose 14.9% of body weight
      over 68 weeks (2.4mg once per week), while tirzepatide has been shown to help patients lose 20.9% of body weight
      over 72 weeks (15mg once per week).
    </P>

    <P className="mb40">
      For those seeking the more affordable option, we recommend semaglutide. For those seeking the most effective
      treatment, we recommend tirzepatide
    </P>

    <h2>Explaining Dosing</h2>

    <P className="mb12">
      All patients start on the lowest dose and, depending on whether they see results and tolerate the medications, may
      be titrated by their Maximus doctor. If titrated, your monthly subscription will be adjusted on the next billing
      cycle.*
    </P>

    <div className="magician_treatment_plan__images">
      <div>
        <h3>Semaglutide</h3>
        <img src={ExplainSemaglutideChart} />
      </div>
      <div>
        <h3>Tirzepatide</h3>
        <img src={ExplainTirzepatideChart} />
      </div>
    </div>

    <P className="mb12">
      *Eligibility for treatment is determined by your assigned Maximus doctor based on your medical history and lab
      results
    </P>

    <WhiteButton text="Close" onClick={() => setModalTreatmentPlanInfo(false)} />
  </>
);

export default ModalBodyTreatmentInformation;
