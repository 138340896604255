import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import Input from 'app/components/common/formik/Input';
import { selectCustomerId } from 'app/selectors/customer';
import './css/CouponCodeInput.scss';

import { apiRequestPromise } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';

const NOT_FOUND_ERROR = 'not_found';
const NOT_VALID_FOR_PRODUCT_ERROR = 'not_valid_for_product';
const EXPIRED_ERROR = 'expired';
const ALREADY_APPLIED_ERROR = 'already_applied';
const CAPACITY_REACHED_ERROR = 'capacity_reached';

const errorMessages = {
  [NOT_FOUND_ERROR]: 'Promo Code not found',
  [NOT_VALID_FOR_PRODUCT_ERROR]: 'not a valid Promo Code',
  [EXPIRED_ERROR]: 'Promo Code has expired',
  [ALREADY_APPLIED_ERROR]: 'Promo Code has already been applied',
  [CAPACITY_REACHED_ERROR]: 'Capacity reached',
};

const CouponCodeInput = ({ productName, appliedDiscounts }) => {
  const dispatch = useAppDispatch();
  const user_id = useAppSelector(selectCustomerId);
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const onSubmit = async () => {
    if (promoCode.trim() == '') {
      setErrorMessage("Promo Code can't be empty");
      return false;
    }
    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'create_discount_with_promo_code',
        user_id,
        params: {
          promo_code: promoCode.trim(),
          product_name: productName,
        },
      });
      dispatch(apiRequestSuccess(resp));
      setErrorMessage('');
      setPromoCode('');
      setShowCouponInput(false);
    } catch (e: any) {
      const errorType: keyof typeof errorMessages = await e.parsedJson.errors.promo_code;
      const message = `“${promoCode}” ${errorMessages[errorType]}`;
      setErrorMessage(message);
      dispatch(apiRequestFailure({ message, reqId: 42, request: {} }));
    }
  };

  return (
    <div className="coupon-code-input">
      {!showCouponInput ? (
        <button onClick={() => setShowCouponInput(true)} className="coupon-code-input__button">
          {appliedDiscounts > 0 ? 'Apply Different Promo Code' : 'Apply Promo Code'}
        </button>
      ) : (
        <>
          {errorMessage && <div className="coupon-code-input__error">{errorMessage}</div>}
          <Input
            type="text"
            name="promoCode"
            placeholder="Enter promo code"
            value={promoCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPromoCode(e.target.value)}
            insideElement={
              <button
                className="coupon-code-input__button coupon-code-input__button__apply"
                type="button"
                onClick={onSubmit}
              >
                Apply
              </button>
            }
          />
        </>
      )}
    </div>
  );
};

export default CouponCodeInput;
