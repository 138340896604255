import { useEffect } from 'react';
import { isDev } from 'app/helpers/env';
import Cookies from 'js-cookie';
import { each } from 'lodash';
import * as Sentry from '@sentry/react';
import { backendLog } from './LogFrontendEvents';
import maximusDebug from './maximusDebug';

const backendCookiesTracking: Record<string, any> = {};

export function useMarketingTrackingWatcher() {
  useEffect(() => {
    const watcherId = setInterval(() => {
      const allCookies = Cookies.get();
      each(allCookies, (cookie, cookieKey) => {
        if (cookieKey.startsWith('_mte_') && !backendCookiesTracking[cookieKey]) {
          const { key: deduplicationKey, type, data } = JSON.parse(atob(cookie));
          if (!deduplicationKey || !type) {
            // Drop old cookies
            return;
          }
          backendCookiesTracking[cookieKey] = true;
          marketingTrack(type, deduplicationKey, data, true).then(() => {
            backendCookiesTracking[cookieKey] = undefined;
          });
        }
      });
    }, 10);

    return () => clearInterval(watcherId);
  });

  return [];
}

async function checkEventKey(deduplicationKey: string) {
  const response = await fetch('/api/frontend/checking', {
    method: 'POST',
    body: JSON.stringify({
      event_key: deduplicationKey,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  });

  return response;
}

async function trackEvent(deduplicationKey: string): Promise<boolean> {
  const response = await checkEventKey(deduplicationKey);

  return response.ok;
}

export function marketingTrack(eventType: string, deduplicationKey: string, data: object = {}, isBackend = false) {
  if (!window.dataLayer && !window.ga && !window.analytics) {
    return Promise.resolve();
  }
  const payload = {
    event_type: eventType,
    ...data,
  };
  return trackEvent(deduplicationKey)
    .then((ok) => {
      if (ok) {
        backendLog('ge', payload);

        if (isDev()) {
          maximusDebug('Tracking')('fake ga event %O', eventType);
        }
        if (window.dataLayer) {
          window.dataLayer.push({ ...data, event: eventType });
        } else if (window.ga) {
          window.ga('send', 'event', { eventCategory: eventType });
        }

        backendLog('segment', payload);

        if (isDev()) {
          maximusDebug('Tracking')('fake segment event %O', eventType);
        }
        if (window.analytics) {
          window.analytics.track(eventType, data);
        }
        if (isBackend) {
          backendLog('backend', {
            key: deduplicationKey,
            payload: payload,
          });
        }
      }
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: { payload },
      });
    });
}
