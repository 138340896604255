import React from 'react';
import { useExperiment } from 'app/utils/useExperiment';

const PlanServices = () => {
  const SHOW_REDESIGNED_CHECKOUT =
    useExperiment('testosterone_checkout_redesign_control', 'testosterone_checkout_redesign_variation_0') ===
    'testosterone_checkout_redesign_variation_1';

  return SHOW_REDESIGNED_CHECKOUT ? (
    <div className="plan_description mt24">
      <h2 className="title_without_line">Plans Include:</h2>
      <ul style={{ listStyleType: 'disc', paddingLeft: '25px' }}>
        <li>Medication</li>
        <li>Doctor Messaging</li>
        <li>Treatment Customization</li>
        <li>Concierge Support & Coaching</li>
        <li>Free Shipping</li>
      </ul>
    </div>
  ) : null;
};

export default PlanServices;
