import { P } from 'app/components/common/Typography';
import { LabKitTypes } from 'app/constants/Products';
import PriceFormatter from 'app/components/common/PriceFormatter';
import { SecondaryButton } from 'app/components/common/Button';
import React from 'react';
import heic2any from 'heic2any';

const MagicianLabWidget = ({
  setFieldValue,
  setOwnLabFile,
  priceCalculator,
  use_own_lab,
  lab_kit_type,
  setOpenModalAtHomeLab,
  setOpenModalOwnLab,
  own_lab_file_uploaded,
  byolFormSubmitted,
  inputFile,
}) => {
  const atHomeLabPrice = priceCalculator.atHomeLabPrice() / 100;

  const handleLabTypeChange = (evt) => {
    setFieldValue('lab_kit_type', evt.target.value);
    setFieldValue('use_own_lab', evt.target.value === LabKitTypes.UseYourOwnLab);
  };

  const onImageChange = ({ target }) => {
    const file = target.files?.[0];
    target.value = '';

    if (file) {
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 }).then((newImage) => {
          setOwnLabFile(newImage);
        });
      } else {
        setOwnLabFile(file);
      }
    }
  };

  return (
    <>
      <P className="mb12 lab_step_paragraph_format">
        Since you indicated during the medical questionnaire you have Hypertension and/or Kidney Disease, your doctor
        will require you to provide lab work within 60 days of starting treatment. <br />
        <br /> You can choose to provide your own lab work today or use Maximus' convenient at-home lab testing. <br />
        <br /> Don’t worry – your doctor will not delay writing your initial prescription. Your doctor will review your
        medical questionnaire responses and place your order within 48 hours (Mon-Fri) if qualified.{' '}
      </P>
      <div className="price_row mt24 mb12">
        <div className="minW30">
          <input
            type="radio"
            data-testid="use-lab-kit"
            name="lab_kit_type"
            value={LabKitTypes.AtHomeLabKit}
            className="radio_button"
            checked={!use_own_lab && lab_kit_type === LabKitTypes.AtHomeLabKit}
            onChange={handleLabTypeChange}
          />
        </div>
        <P className="bold">At-Home Lab Kits</P>
        <PriceFormatter price={atHomeLabPrice} />
      </div>
      <div className="display_flex">
        <div className="minW30" />
        <P className="mb12 ">
          Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and do not
          require finger-pricking. Results are available FAST (typically within four business days).{' '}
          <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.AtHomeLabKit)}>
            Full details
          </a>
        </P>
      </div>
      <div className="price_row mt24 mb12">
        <div className="minW30">
          <input
            type="radio"
            data-testid="use-own-lab"
            name="use_own_lab"
            className="radio_button"
            checked={use_own_lab || lab_kit_type === LabKitTypes.UseYourOwnLab} // for old customers use_own_lab still may be set
            value={LabKitTypes.UseYourOwnLab}
            onChange={handleLabTypeChange}
          />
        </div>
        <P className="bold">Use Your Own Lab Test</P>
        <div />
      </div>
      <div className="display_flex">
        <div className="minW30" />
        <div>
          <P className="mb12">
            You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 90 days
            after starting treatment, or as required by your licensed Maximus doctor).
            <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.UseYourOwnLab)}>
              {' '}
              Full details
            </a>
          </P>
          {use_own_lab && (
            <>
              {!own_lab_file_uploaded || !byolFormSubmitted ? (
                <div className="byol-form-warning">
                  This option requires lab results be uploaded in order to check out.
                </div>
              ) : (
                <div className="byol-form-success">Successfully uploaded</div>
              )}
              <div className="photo_upload">
                <SecondaryButton
                  data-testid="own-lab-modal-open"
                  className="outlined-button mt20"
                  text="UPLOAD LAB RESULTS"
                  onClick={() => setOpenModalOwnLab(true)}
                  disabled={!use_own_lab}
                  size={'small'}
                />
              </div>
              <input
                ref={inputFile}
                type="file"
                id="file"
                style={{ display: 'none' }}
                accept="image/*, application/pdf"
                onChange={onImageChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MagicianLabWidget;
