import { List, Map } from 'immutable';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { AdminUserImm, ImmutableList, ImmutableMap, ImmutableMapping } from 'app/types/admin';
import {
  CustomerUser,
  CustomerUserImm,
  Discount,
  HistoryItem,
  IntakeLabOrder,
  KingIntake,
  LabSchedules,
  MaximusProduct,
  ProductIntake,
} from 'app/types/admin/customerUser';
import { AvailableProducts } from 'app/constants/Products';

export const selectState = (state) => state;

export const selectMostRecentApiProblem = (state) => state.admin.get('mostRecentApiProblem');

export const selectNotices = (state) => state.admin.getIn(['ui', 'notices'], List());

export const selectAdmin = (state): AdminUserImm => state.admin.get('admin');

export const selectLabOrderKits = createSelector(selectAdmin, (admin) => admin.get('lab_order_kits'));

const selectRouterPathname = (state) => state.router.getIn(['location', 'pathname']);

export const selectUserIdFromPath = createSelector(selectRouterPathname, (path): string | null => {
  const adminUserRoute = '/admin/users/:userId';
  const match = matchPath(path, { path: adminUserRoute });
  if (match) {
    return (match.params as any).userId;
  }

  return null;
});

export const selectOpenModal = (state) => state.admin.get('openModal');

export const selectUserHistory = createSelector(
  selectUserIdFromPath,
  selectState,
  (customerIdFromPath, state): ImmutableList<HistoryItem> =>
    state.admin.getIn(['user_history', customerIdFromPath], List()),
);
export const selectUserStripeInfo = createSelector(selectUserIdFromPath, selectState, (customerIdFromPath, state) =>
  state.admin.getIn(['user_stripe_info', customerIdFromPath], Map()),
);
export const selectCustomerStripeInfo = (state, customerId) =>
  state.admin.getIn(['user_stripe_info', customerId], Map());

export const selectLoginHistory = createSelector(selectUserIdFromPath, selectState, (customerIdFromPath, state) =>
  state.admin.getIn(['loginHistory', customerIdFromPath], List()),
);

export const selectUsers = (state): List<CustomerUserImm> => state.admin.get('users');

export const selectSupportMessages = (state) => state.admin.getIn(['support_messages'], List());
const selectThreads = (state) => state.admin.getIn(['threads'], Map());

export const selectUser = createSelector(selectUsers, selectUserIdFromPath, (users, customerIdFromPath) =>
  users.find((u) => u.get('id') === customerIdFromPath),
);
export const selectLabOrders = createSelector(selectUser, (user) =>
  user!.get('lab_orders', List() as ImmutableList<IntakeLabOrder>),
);
export const selectLabSchedules = createSelector(selectUser, (user) =>
  user!.get('lab_schedules', {} as ImmutableMap<LabSchedules>),
);

export const selectUserProducts = createSelector(selectUser, (user) => user?.get('maximus_products'));

export const selectUserProduct = createSelector(
  [selectUserProducts, (_state, productName: AvailableProducts) => productName],
  (products, productName: AvailableProducts) => products?.get(productName) as ImmutableMap<MaximusProduct>,
);

export const selectUserDiscounts = createSelector(
  selectUser,
  (customer) => customer?.get('all_customer_discounts', List() as ImmutableList<Discount>) || List<Discount>(),
);

export const selectBuildingBlocks = createSelector(
  [(product: any) => product?.get('opt_in_choice')?.toJS()],
  (opt_in_choice: any) => opt_in_choice?.include_supplement,
);

export const selectProductIntakes: (
  state: any,
  productName: AvailableProducts,
) => List<ImmutableMapping<ProductIntake>> = createSelector(selectUserProduct, (product) =>
  (product as any)?.get('intakes'),
);
export const selectProductPharmacyShipments = createSelector(selectUserProduct, (product) =>
  product?.get('pharmacy_shipments'),
);
export const selectUserKingProductIntakes = (state): List<ImmutableMapping<KingIntake>> =>
  selectProductIntakes(state, AvailableProducts.King) as unknown as List<ImmutableMapping<KingIntake>>;
export const selectUserProductIntake = (state, productName, intakeName) =>
  selectProductIntakes(state, productName).find((intake) => intake.get('name') === intakeName);
export const selectUserProductIntakeLabVendor = (
  state: any,
  productName: string,
  intakeName: string,
): string | undefined | null => selectUserProductIntake(state, productName, intakeName)?.get('effective_lab');

export const selectUserAdminNotes = createSelector(selectThreads, selectUserIdFromPath, (threads, customerIdFromPath) =>
  threads.getIn([customerIdFromPath, 'admin_notes'], List()),
);

export const selectUserFiles = createSelector(selectUser, (user) =>
  user!.get('files', List() as ImmutableMapping<CustomerUser['files']>),
);
// export const selectUserFiles = (state) => selectUser(state)?.get('files', Map())

export const selectCustomerThread = createSelector(selectThreads, selectUserIdFromPath, (threads, customerIdFromPath) =>
  threads.getIn([customerIdFromPath, 'support'], List()),
);

export const selectUserAppointment = createSelector(selectUser, (user) => user!.get('appointment'));
