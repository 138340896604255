import { P } from 'app/components/common/Typography';
import React, { FC, ReactNode, useState } from 'react';
import ExplainSemaglutideChart from 'images/explain_semaglutide_chart.svg';
import ExplainTirzepatideChart from 'images/explain_tirzepatide_chart.svg';
import { useFormikContext } from 'formik';
import PriceFormatter from 'app/components/common/PriceFormatter';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import Divider from '@setproduct-ui/core/Divider';
import ModalBodyTreatmentInformation from './ModalBodyTreatmentInformation';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import IconInfo from 'images/Icon-Info.svg';
import Radio from 'app/components/common/Radio';

const RadioLabel: FC<{
  title: ReactNode;
  subheader?: ReactNode;
  paragraph?: ReactNode;
  icon?: string;
  dosePrice?: string;
  totalPrice?: number;
}> = ({ title, totalPrice }) => {
  return (
    <div className="titles">
      <div className="title">
        <div className="price_row">
          <div>{title}</div>
          <div>
            <PriceFormatter price={totalPrice} />
          </div>
        </div>
      </div>
    </div>
  );
};

const MonthlyTreatmentPlanProducts = () => {
  const tirzepatideAvailable = useAppSelector(selectCustomer)?.get('tirzepatide_available');
  const { setFieldValue, values } = useFormikContext<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const drugVariant = values.drug_variant;
  const chartImageSource = drugVariant === 'semaglutide' ? ExplainSemaglutideChart : ExplainTirzepatideChart;

  const handleSemaglutideClick = () => {
    setFieldValue('drug_variant', 'semaglutide');
    setFieldValue('strength_variant', '0_25mg');
  };
  const handleTirzepatideClick = () => {
    if (!tirzepatideAvailable) return;
    setFieldValue('drug_variant', 'tirzepatide');
    setFieldValue('strength_variant', '2_5mg');
  };

  return (
    <div className="magician_treatment_plan__options">
      <P className="mb20">
        All patients start on the lowest dose and, depending on whether they see results and tolerate the medications,
        may be titrated by their Maximus doctor. If titrated, your monthly subscription will be adjusted on the next
        billing cycle.
      </P>
      <img src={chartImageSource} className="magician_treatment_plan__image" />

      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned"
          onChange={handleSemaglutideClick}
          checked={drugVariant === 'semaglutide'}
          testId="drug-variant-semaglutide"
          label={
            <RadioLabel
              title={
                <>
                  Semaglutide <img className="ml12" onClick={() => setModalOpen(true)} src={IconInfo} />
                </>
              }
              totalPrice={299.99}
            />
          }
        />
      </div>
      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned"
          onChange={handleTirzepatideClick}
          testId="drug-variant-tirzepatide"
          checked={drugVariant === 'tirzepatide'}
          disabled={!tirzepatideAvailable}
          label={
            <RadioLabel title={`Tirzepatide${tirzepatideAvailable ? '' : ' (unavailable)'}`} totalPrice={499.99} />
          }
        />
      </div>
      <Divider className="mb24" />
      <SetProductDialog
        isOpen={modalOpen}
        text={<ModalBodyTreatmentInformation setModalTreatmentPlanInfo={setModalOpen} />}
        onClose={() => setModalOpen(false)}
        className="manage_subscription__cancel_modal magician_treatment_plan__modal"
      />
    </div>
  );
};

export default MonthlyTreatmentPlanProducts;
