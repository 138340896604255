import { AvailableProducts } from 'app/constants/Products';
import KingCheckoutHelper from 'app/components/customer/steps/Payment/king/KingCheckoutHelper';
import { ImmutableMap } from 'app/types/admin';
import {
  Product,
  CustomerUserImm,
  KingIntake,
  KingProduct,
  LoverIntake,
  LoverProduct,
  MagicianIntake,
  MagicianProduct,
  WarriorIntake,
  WarriorProduct,
  ProductIntakeImm,
} from 'app/types/admin/customerUser';
import MagicianCheckoutHelper from 'app/components/customer/steps/Payment/magician/MagicianCheckoutHelper';
import LoverCheckoutHelper from 'app/components/customer/steps/Payment/lover/LoverCheckoutHelper';
import WarriorCheckoutHelper from 'app/components/customer/steps/Payment/warrior/WarriorCheckoutHelper';

const createCheckoutHelper = (
  intake: ProductIntakeImm,
  customer: CustomerUserImm,
  product: ImmutableMap<Product>,
  query_params: any = undefined,
) => {
  switch (intake.get('product_name')) {
    case AvailableProducts.King:
      return new KingCheckoutHelper(
        customer,
        intake as ImmutableMap<KingIntake>,
        product as ImmutableMap<KingProduct>,
        query_params,
      );
    case AvailableProducts.Magician:
      return new MagicianCheckoutHelper(
        customer,
        intake as ImmutableMap<MagicianIntake>,
        product as ImmutableMap<MagicianProduct>,
      );
    case AvailableProducts.Lover:
      return new LoverCheckoutHelper(
        intake as ImmutableMap<LoverIntake>,
        customer,
        product as ImmutableMap<LoverProduct>,
      );
    case AvailableProducts.Warrior:
      return new WarriorCheckoutHelper(
        customer,
        intake as ImmutableMap<WarriorIntake>,
        product as ImmutableMap<WarriorProduct>,
      );
    default:
      throw new Error('Invalid product');
  }
};

export default createCheckoutHelper;
