import React from 'react';
import '../css/Payment.scss';
import GenericSubscriptionDetails from 'app/components/customer/steps/Payment/GenericExp/GenericSubscriptionDetails';
import GenericBuildingBlocks from 'app/components/customer/steps/Payment/GenericExp/GenericBuildingBlocks';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import '../css/GenericPaymentPage.scss';
import PriceCalculator from 'app/helpers/priceCalculator';
import { Product, CheckoutHelper } from 'app/types/admin/customerUser';
import { AvailableProducts } from 'app/constants/Products';
import { ImmutableMap } from 'app/types/admin';
import PlanWidget from './PlanWidget';

type Props = {
  currentProductName: AvailableProducts;
  multimonthPeriod: string;
  multimonthPlan: number;
  subProductName: string;
  multimonthEnabled: boolean;
  checkoutHelper: CheckoutHelper;
  isOnboarding: boolean;
  supplementsOnAnotherIntake: boolean;
  activeProductWithSupplementName: string | undefined;
  productChanged: boolean;
  product: ImmutableMap<Product>;
  priceCalculator: PriceCalculator;
  goToSelectPlanPage?: (e: any) => void;
  goToSelectProductPage?: (e: any) => void;
  goToSelectLabPage?: (e: any) => void;
  onMultimonthPlanChange?: (e: any) => void;
};

const SubscriptionWidget = ({
  currentProductName,
  product,
  priceCalculator,
  goToSelectProductPage,
  goToSelectLabPage,
  multimonthPeriod,
  multimonthPlan,
  isOnboarding,
  supplementsOnAnotherIntake,
  activeProductWithSupplementName,
  multimonthEnabled,
  checkoutHelper,
  onMultimonthPlanChange,
  productChanged,
  subProductName,
}: Props) => {
  const SubscriptionSummaryComponent = checkoutHelper.subscriptionSummaryComponent();

  return (
    <div className="mb12">
      <GenericSubscriptionDetails
        currentProductName={currentProductName}
        product={product}
        priceCalculator={priceCalculator}
        multimonthEnabled={checkoutHelper.multimonthEnabled()}
        handleChangeProduct={goToSelectProductPage}
        handleChangeLab={goToSelectLabPage}
        subProductName={subProductName}
        checkoutHelper={checkoutHelper}
      />
      <div className="payment_card mt24 pt0">
        <GenericBuildingBlocks
          currentProductName={currentProductName}
          supplementsOnAnotherIntake={supplementsOnAnotherIntake}
          multimonthEnabled={multimonthEnabled}
          multimonthPlan={multimonthPlan}
          priceCalculator={priceCalculator}
          bbOutOfStock={checkoutHelper.bbOutOfStock()}
          activeProductWithSupplementName={activeProductWithSupplementName}
          encloPregNewPricing={checkoutHelper.encloPregNewPricing()}
          isOnboarding={isOnboarding}
          multimonthPeriod={multimonthPeriod}
        />

        <SubscriptionSummaryComponent
          multimonthPeriod={multimonthPeriod}
          currentProductName={currentProductName}
          multimonthPlan={multimonthPlan}
          priceCalculator={priceCalculator}
          isOnboarding={isOnboarding}
          productChanged={productChanged}
          nextBillingCycle={nextBillingCycle(currentProductName)}
        />
        <PlanWidget
          onMultimonthPlanChange={onMultimonthPlanChange}
          subProductName={subProductName}
          checkoutHelper={checkoutHelper}
          priceCalculator={priceCalculator}
        />
      </div>
    </div>
  );
};

export default SubscriptionWidget;
