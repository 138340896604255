import React from 'react';
import { PrimaryButton } from 'app/components/common/Button';
import { P } from 'app/components/common/Typography';
import { useAppSelector } from 'app/helpers/hooks';
import { useDispatch } from 'react-redux';
import { selectCurrentIntake, selectCurrentIntakeProduct, selectNextStepPath } from 'app/selectors/customer';
import { apiRequestUserCommand } from 'app/actions/customer';
import { push } from 'connected-react-router/immutable';
import { selectUserProduct } from 'app/selectors/admin';
import { LabKitTypes } from 'app/constants/Products';

type Props = {
  setUnavailableProductDialog: (isOpen: boolean) => void;
  setOralTrtAvailable: (isAvailable: boolean) => void;
};

const UnavailableProductModal = ({ setUnavailableProductDialog, setOralTrtAvailable }: Props) => {
  const dispatch = useDispatch();
  const intake = useAppSelector(selectCurrentIntake);
  const kingV2AvailableStates = intake.get('king_v2_available_states');
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectUserProduct(state, currentProductName));
  const labKitType = product?.get('lab_kit_type');

  const nextStep = useAppSelector(selectNextStepPath);

  const onSuccessActionCreator = (_, _stateAfterSuccess) => push(nextStep);

  const modifyLabSelection = () => {
    const action = apiRequestUserCommand({
      cmdType: 'lab_selection_opt_in',
      params: {
        lab_kit_type: 'at_home_lab_kit',
        product_name: currentProductName,
      },
    });
    dispatch(action);
  };

  const updateProductSelection = () => {
    if (labKitType !== LabKitTypes.UseYourOwnLab) {
      modifyLabSelection();
    }
    const action = apiRequestUserCommand({
      cmdType: 'select_king_v2_product',
      params: { selected_king_v2_product: 'enclomiphene_pregnolone', intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
    setUnavailableProductDialog(false);
    setOralTrtAvailable(true);
  };

  const handleCorrectAddress = () => {
    setUnavailableProductDialog(false);
    dispatch(push(nextStep));
  };

  return (
    <>
      <P className="mt12">
        We recommend switching to our EP Protocol and we will notify you as soon as Oral TRT becomes available in your
        state.
      </P>
      <P className="mt12">Oral TRT is currently only offered in these states: {kingV2AvailableStates.join(', ')}</P>
      <PrimaryButton className="mt24" text="Switch to EP Protocol" onClick={updateProductSelection} />
      <PrimaryButton className="mt24" text="Correct Address" onClick={handleCorrectAddress} />
    </>
  );
};

export default UnavailableProductModal;
