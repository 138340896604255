const protocolNames = {
  king: 'Testosterone',
  lover: 'Blood Flow',
  warrior: 'Hair Improvement',
  magician: 'Weight Loss',
  king_lab: 'Testosterone Lab',
  magician_lab: 'Weight Loss Lab',
  all: 'All',
};
export default protocolNames;
