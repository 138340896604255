import React from 'react';
import { connect } from 'react-redux';

import { P, Title } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import './css/Acknowledgement.scss';
import protocolNames from 'app/utils/protocolNames';
import { useAppSelector } from 'app/helpers/hooks';
import { KingV2SubProducts, AvailableProducts } from 'app/constants/Products';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import SlidersIcon from 'images/testosterone-intro/sliders.svg';
import ShippingIcon from 'images/testosterone-intro/fast-free-shipping.svg';
import MonitoringIcon from 'images/testosterone-intro/at-home-monitoring.svg';
import { useExperiment } from '../../../utils/useExperiment';

const mapStateToProps = (_) => {
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestOnboardingStepPath({ customer: stateAfterSuccess }));

  return {
    onSuccessActionCreator,
  };
};

const benefits = ['Increased energy', 'More lean mass', 'Greater motivation', 'More confidence'];
const access = [
  'Prescription medication capsules (if qualified)',
  'Licensed doctor consultations',
  'Live coaching & community',
  'Free 2 day priority shipping for meds',
];

const productDetails = {
  [KingV2SubProducts.EncloPregnolone]: {
    title: 'EP',
    path: '/enclomiphene',
  },
  [KingV2SubProducts.Trt]: {
    title: 'Oral TRT',
    desc: "The Oral TRT protocol delivers native testosterone enveloped in a lipid matrix to allow for lymphatic absorption, bypassing the liver's first-pass metabolism. Oral TRT sets the gold standard for a convenient, effective, and liver-safe way to take TRT.",
    path: '/oral-trt',
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    title: 'Oral TRT+',
    desc: "The Oral TRT+ protocol delivers a unique release of testosterone designed to improve your energy and body composition without impacting your fertility markers, liver, kidney, or testicular function. It's effective - with none of the painful injections, messy creams, or side effects associated with other testosterone treatments.",
    path: '/testosterone-replacement-therapy',
  },
};

const productDetailsVariationTwo = {
  default: {
    title: 'Boost Your Testosterone with Science-Backed Protocols',
    desc: ['Personalized protocols to elevate your testosterone levels to the top percentile.'],
    gettingStarted: [
      'Receive your At-Home Lab Kit or Quest Labs form to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we send another lab-kit or requisition form to ensure progress. Your doctor will adjust your dosage for optimal results.',
    ],
  },
  [KingV2SubProducts.EncloPregnolone]: {
    title: 'Boost Your Natural Testosterone With EP Protocol',
    desc: [
      'A convenient prescription tablet that naturally stimulates your body’s testosterone production.',
      'Potential for a 2x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we send another lab-kit to ensure progress. Your doctor will adjust your dosage for optimal results.',
    ],
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    title: 'Unlock Elite Testosterone Levels With Oral TRT+ Protocol',
    desc: [
      'Boost energy and body composition without impacting your fertility markers, liver, kidney, or testicular function.',
      'Achieve a 2-5x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit or Quest Labs form to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we’ll send another lab-kit or requisition form to monitor your progress, and your doctor will fine-tune your dosage as needed.',
    ],
  },
  [KingV2SubProducts.Trt]: {
    title: 'Unlock Elite Testosterone Levels With Oral TRT Protocol',
    desc: [
      'Boost energy and body composition without impacting your liver, kidney, or testicular function.',
      'Achieve a 2-5x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit or Quest Labs form to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we’ll send another lab-kit or requisition form to monitor your progress, and your doctor will fine-tune your dosage as needed.',
    ],
  },
};

const Acknowledgement = ({ onSuccessActionCreator, step, dispatch }) => {
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, AvailableProducts.King));
  const kingSubProductName = product?.get('opt_in_choice')?.get('selected_king_v2_product');
  const protocolName = productDetails[kingSubProductName]?.title || protocolNames[AvailableProducts.King];

  const onClick = () => {
    const cmdType = 'answer_questions';
    const params = { [step]: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  const SHOW_REDESIGNED_INTRO_SCREEN =
    useExperiment('testosterone_intro_screen_redesign_control', 'testosterone_intro_screen_redesign_variation_1') ===
    'testosterone_intro_screen_redesign_variation_2';

  const variationOne = () => {
    return (
      <div className="p16 king-intro">
        <h1>
          The {productDetails[kingSubProductName]?.title || <span>{protocolNames[AvailableProducts.King]}</span>}{' '}
          Protocol
        </h1>
        <P className="mt16">
          {productDetails[kingSubProductName]?.desc ||
            `The ${protocolName} Protocol uses a convenient, prescription tablet that stimulates your body’s own natural testosterone production. It’s effective – with none of the painful injections, messy creams, or side effects associated with other testosterone treatments.`}
        </P>
        <P className="bold mt20">Benefits include:</P>
        <ul>
          {benefits.map((benefit) => (
            <li key={benefit}>
              <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
            </li>
          ))}
        </ul>
        <P className="bold mt36">You will have access to:</P>
        <ul>
          {access.map((a) => (
            <li key={a}>
              <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
            </li>
          ))}
        </ul>
        <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
      </div>
    );
  };

  const variationTwo = () => {
    const productDetails = productDetailsVariationTwo[kingSubProductName || 'default'];
    const variationTwoIcons = [MonitoringIcon, ShippingIcon, SlidersIcon];
    const gettingStartedHeadings = ['Check your levels', 'Get Your Prescription', 'Continuous Optimization'];

    const productDescription = () => {
      if (productDetails.desc.length > 1) {
        return productDetails.desc.map((item, i) => (
          <P className="lab_step_paragraph_format ml8 mt8" key={i}>
            <li className="indented-list-item">{item}</li>
          </P>
        ));
      } else {
        return <P className="lab_step_paragraph_format">{productDetails.desc[0]}</P>;
      }
    };

    const gettingStarted = () =>
      productDetails.gettingStarted.map((item, index) => (
        <div className="mt20" style={{ display: 'flex', alignItems: 'start', gap: '5%' }} key={index}>
          <img src={variationTwoIcons[index]} alt="" />
          <div>
            <Title size="list_header">{gettingStartedHeadings[index]}</Title>
            <P className="lab_step_paragraph_format mt4">{item}</P>
          </div>
        </div>
      ));

    return (
      <div className="p16">
        <Title className="mt20 mb16" size="xl">
          {productDetails.title}
        </Title>
        <div className="mb24">{productDescription()}</div>
        <PaymentCard>
          <Title size="subheading">Getting Started</Title>
          {gettingStarted()}
        </PaymentCard>
        <PrimaryButton className="mt32" data-testid="next-step" text="Start my journey" onClick={onClick} />
      </div>
    );
  };

  return SHOW_REDESIGNED_INTRO_SCREEN ? variationTwo() : variationOne();
};

export default connect(mapStateToProps)(Acknowledgement);
